import * as api from '../../api';

const handleLogin = ({
  cognitoUser,
  setErrorMessage,
  setIsLoggingIn,
  setMfaModalOpen,
}) => {
  const {
    idToken,
    refreshToken,
    accessToken,
  } = cognitoUser.signInUserSession;

  const handleCatch = (error) => {
    setErrorMessage(error.response?.data?.error ?? error.response?.data?.detail ?? null);
    setIsLoggingIn(false);
    if (setMfaModalOpen) {
      setMfaModalOpen(false);
    }
  };

  api.loginCompassPlus(idToken.jwtToken, refreshToken.token, accessToken.jwtToken)
    .then(() => {
      Promise.all([
        api.loginCareersPartners(idToken.jwtToken, refreshToken.token, true),
        api.loginCompassClassic(idToken.jwtToken, refreshToken.token),
      ])
        .then(() => window.location = process.env.REACT_APP_COMPASS_PLUS_URL)
        .catch(handleCatch)
      ;
    })
    .catch(handleCatch)
  ;
};

export default handleLogin;
